import { msalInstance, loginRequest } from '@/config/authConfig'
import {
  type AuthenticationResult,
  InteractionType,
  type RedirectRequest,
  type SilentRequest,
  PublicClientApplication
} from '@azure/msal-browser'
import { useMsal, CustomMsalEvent } from '@/plugins/msalPlugin'
import { getCookie } from '@/shared/utils/cookieUtils'

import type { Amber } from '@hms-kontoret/amber.types'
import type { IsAuthenticatedResult } from '@/shared/types'

export const tryProxyAuthentication = async (): Promise<string | null> => {
  // Early return if hostname doesn't include 'proxy'
  if (!window.location.hostname.includes('proxy')) return null

  const msalToken = useMsal()?.token?.proxy
  if (msalToken) return msalToken

  const cookieToken = getCookie('token')
  if (!cookieToken) return null

  document.dispatchEvent(
    new CustomEvent(CustomMsalEvent.PROXY_LOGIN, { detail: cookieToken })
  )

  return cookieToken
}

export async function isAuthenticated(
  instance: PublicClientApplication,
  interactionType: InteractionType,
  loginRequest: RedirectRequest
): Promise<IsAuthenticatedResult> {
  const isProxyAuthenticated = await tryProxyAuthentication()
  if (isProxyAuthenticated) return { authenticated: true }

  const response = await instance.handleRedirectPromise().catch(() => null)
  const accounts = instance.getAllAccounts()

  if (accounts.length > 0)
    return { authenticated: true, state: response?.state ?? null }

  if (interactionType === InteractionType.Redirect) {
    // Redirect immediately without resolving
    await instance.loginRedirect(loginRequest)
    return { authenticated: false }
  }

  return { authenticated: false }
}

export const acquireAuthenticationToken =
  async (): Promise<Amber.API.V1.AuthToken> => {
    const silentRequest = { ...loginRequest } as SilentRequest
    const proxyAuthResult = await tryProxyAuthentication()
    if (proxyAuthResult) return { accessToken: '', idToken: proxyAuthResult }

    try {
      const response: AuthenticationResult =
        await msalInstance.acquireTokenSilent(silentRequest)
      const { accessToken, idToken } = response
      return { accessToken, idToken }
    } catch (error) {
      console.error('Acquire token failed', error)
      throw error
    }
  }

export const logout = async () => {
  document.dispatchEvent(new CustomEvent(CustomMsalEvent.LOGOUT))
}

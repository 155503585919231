import type { RouteManifest } from '@hms-kontoret/amber.types'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const manifest: RouteManifest = {
  name: 'Employer',
  route: {
    name: 'Employer',
    path: '/employer',
    alias: ['/personnel'],
    meta: {
      routeTitle: 'menu__title__personnel',
      navMenuIcon: 'n-icon-users-wm',
      includeInNavMenu: true
    }
  },
  children: [
    {
      name: 'Employees',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Employees',
        path: 'ansatte/ansatte',
        meta: {
          routeTitle: 'menu__title__employees',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.EMPLOYER_EMPLOYEES]
        }
      }
    },
    {
      name: 'EmployeeInterviews',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'EmployeeInterviews',
        path: 'employee_interview/employee_interview',
        meta: {
          routeTitle: 'menu__title__employee_interviews',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.EMPLOYEE_INTERVIEW]
        }
      }
    },
    {
      name: 'EmployeeForms',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'EmployeeForms',
        path: 'employee_forms/employee_forms',
        meta: {
          routeTitle: 'menu__title__employee_forms',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.EMPLOYEE_FORMS]
        }
      }
    },
    {
      name: 'Competence',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Competence',
        path: 'competence',
        meta: {
          routeTitle: 'menu__title__competence',
          requiresAccessRights: [MENU_ACCESS.EMPLOYER_COMPETENCE]
        }
      }
    },
    {
      name: 'WorkSchedules',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'WorkSchedules',
        path: 'arbeidsplan/arbeidsplan',
        meta: {
          routeTitle: 'menu__title__work_schedules',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.WORK_SCHEDULE]
        }
      }
    },
    {
      name: 'Vacation',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Vacation',
        path: 'vacation',
        meta: {
          routeTitle: 'menu__title__vacation',
          requiresAccessRights: [MENU_ACCESS.EMPLOYER_VACATION]
        }
      }
    },
    {
      name: 'Absence',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Absence',
        path: 'absence',
        meta: {
          routeTitle: 'menu__title__absence',
          requiresAccessRights: [MENU_ACCESS.EMPLOYER_ABSENCE]
        }
      }
    },
    {
      name: 'Hours',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Hours',
        path: 'timeregistrering/timeregistrering',
        meta: {
          routeTitle: 'menu__title__hours',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.TIME_REGISTRATION]
        }
      }
    },
    {
      name: 'Response',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Response',
        path: 'response',
        meta: {
          routeTitle: 'menu__title__response',
          requiresAccessRights: [MENU_ACCESS.EMPLOYER_SURVEY]
        }
      }
    },
    {
      name: 'Response - Template',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Response template',
        path: 'template',
        meta: {
          routeTitle: 'menu__title__response_template',
          requiresAccessRights: [MENU_ACCESS.RESPONSE_TEMPLATE]
        }
      }
    }
  ]
}

export default manifest

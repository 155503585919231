import type { RouteManifest } from '@hms-kontoret/amber.types'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const manifest: RouteManifest = {
  name: 'UnitInformation',
  route: {
    name: 'UnitInformation',
    path: '/company',
    meta: {
      routeTitle: 'menu__title__unit_information',
      navMenuIcon: 'n-icon-property-location',
      includeInNavMenu: true,
      requiresAccessRights: [MENU_ACCESS.UNIT_INFORMATION]
    }
  },
  children: [
    {
      name: 'CompanyInfo',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'CompanyInfo',
        path: 'company-info',
        alias: ['companyinfo'],
        meta: {
          routeTitle: 'menu__title__company_info'
        }
      }
    },
    {
      name: 'Premises',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Premises',
        path: 'premises',
        meta: {
          routeTitle: 'menu__title__premises',
          requiresAccessRights: [MENU_ACCESS.PREMISES]
        }
      }
    },
    {
      name: 'Departments',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Departments',
        path: 'departments',
        meta: {
          routeTitle: 'menu__title__departments'
        }
      }
    },
    {
      name: 'OrganizationalCharts',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'OrganizationalCharts',
        path: 'organizational-charts',
        meta: {
          routeTitle: 'menu__title__organizational_charts'
        }
      }
    },
    {
      name: 'Goals',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Goals',
        path: 'malsetting/malsettings',
        meta: {
          routeTitle: 'menu__title__goals',
          isMonolith: true
        }
      }
    },
    {
      name: 'ObligationAndAuthority',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'ObligationAndAuthority',
        path: 'ansvarmyndighet/ansvarmyndighet',
        meta: {
          routeTitle: 'menu__title__obligation_and_authority',
          isMonolith: true
        }
      }
    }
  ]
}

export default manifest

import { defaultLanguage, languages, renderDocumentNode } from './configuration'
import type { I18nData } from './i18nData'

export const updateMessages = (contentfulEntries: any): I18nData => {
  const localeMessages = {} as any
  languages.forEach(lang => {
    localeMessages[lang] = {}
  })

  contentfulEntries.entries
    .filter((entry: any) => {
      return (
        (entry.sys.contentType.sys.id === 'string' ||
          entry.sys.contentType.sys.id === 'text') &&
        entry.fields &&
        entry.fields.key
      )
    })
    .forEach((entry: any) => {
      const nodeType = entry.fields.text[defaultLanguage].nodeType
      const key = entry.fields.key[defaultLanguage]
      const defaultContent = entry.fields.text[defaultLanguage]

      // Process content for each language
      languages.forEach(lang => {
        let content = entry.fields.text[lang] ?? defaultContent

        if (content)
          content =
            nodeType === 'document' ? renderDocumentNode(content) : content

        localeMessages[lang][key] = content
      })
    })

  return localeMessages
}

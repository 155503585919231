import { useGtm } from '@/plugins/gtmPlugin'
import type { GtmDataLayer, GtmEvent } from '@hms-kontoret/amber.types'

export const updateDataLayer = (data: GtmDataLayer) => {
  // We use window.dataLayer because we want to push custom data to the GTM dataLayer
  window.dataLayer?.push(data)
}

export const trackEvent = (eventName: GtmEvent, eventTriggerId: string) => {
  const gtm = useGtm()
  gtm.trackEvent({
    event: eventName,
    eventTriggerId: eventTriggerId
  })
}

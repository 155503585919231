import type { Router } from 'vue-router'
import { parseUrlToRouteObject } from '@/shared/utils/routeUtils'
import { Amber } from '@hms-kontoret/amber.types/Commands'

export const listenRemoteRoutes = (router: Router) => {
  window.addEventListener(
    Amber.Command.V1.RouteContext.Route.NavigateTo.toString(),
    ((event: CustomEvent<Amber.Command.V1.RouteContext.Route.NavigateTo>) => {
      const routeObject = parseUrlToRouteObject(event.detail.path)
      router.push(routeObject)
    }) as EventListener
  )

  window.addEventListener(
    Amber.Command.V1.RouteContext.Route.ReplaceState.toString(),
    ((event: CustomEvent<Amber.Command.V1.RouteContext.Route.ReplaceState>) => {
      const routeObject = parseUrlToRouteObject(event.detail.path)
      router.replace(routeObject)
    }) as EventListener
  )
}

export const registerRemoteRoutesListener = (router: Router) => {
  listenRemoteRoutes(router)
}

export default registerRemoteRoutesListener

import { createPutRequest } from '@/shared/requestHandler'

export const useUserService = () => {
  const updateLocale = async (locale: string) => {
    const url = '/common/api/users/my/locale'
    return await createPutRequest(url, locale)
  }

  return {
    updateLocale
  }
}

import type { App } from 'vue'
import { init, loadRemote as _loadRemote } from '@module-federation/runtime'
import { RemoteModule } from '@/shared/types'
import { Remote } from '@hms-kontoret/amber.types'

export const federationPlugin = {
  /*
   * Install the plugin with the provided remote modules
   */
  install: (_: App, remotes: Remote[]) => {
    const getEntry = (module: string) => {
      // Uncomment the following line to use localhost for development
      // if (module === 'iframefusion')
      //   return 'http://localhost:5012/remoteEntry.js'

      return `${import.meta.env.VITE_APP_REMOTE_BASE_URL}/${module}/remoteEntry.js`
    }

    const mfRemotes = remotes.map((remote: Remote) => ({
      name: remote.module,
      entry: remote.entry ?? getEntry(remote.module),
      type: 'module'
    }))

    init({
      name: import.meta.env.VITE_APP_NAME as string,
      remotes: mfRemotes
    })
  }
}

export default federationPlugin

export const useModuleFederation = () => {
  /**
   * Load a remote module
   * @param module The name of the module to load
   * @returns {Promise<RemoteModule>} The remote module
   */
  const loadRemote = async (module: string): Promise<RemoteModule> => {
    const loadedModule = (await _loadRemote(`${module}/entry`)) as RemoteModule
    return {
      name: module,
      mount: loadedModule.mount,
      unmount: loadedModule.unmount
    } as RemoteModule
  }

  return { loadRemote }
}

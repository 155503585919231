import { CustomNavigationClient } from '@/router/routeHelpers/navigationClient'
import { msalInstance } from '@/config/authConfig'
import type { Router } from 'vue-router'
export const configureAuthentication = (router: Router) => {
  const navigationClient = new CustomNavigationClient(router)
  msalInstance.setNavigationClient(navigationClient)

  const accounts = msalInstance.getAllAccounts()
  if (accounts.length > 0) msalInstance.setActiveAccount(accounts[0])
}

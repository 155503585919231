// Used with requiresAccessRights in route manifests to determine which users can access a route
// Example usage: requiresAccessRights: [MENU_ACCESS.MY_HOURS]
export const MENU_ACCESS = {
  ACCOUNT_SETTINGS: 'KONTOINNSTILLINGER',
  ADMIN: 'KONTOADMIN',
  AGREEMENTS: 'AVTALER',
  CORPORATE_REPORT: 'KONSERNRAPPORT',
  CORPORATE: 'KONSERN',
  CONTACTS: 'KONTAKTER',
  DANGEROUS_SUBSTANCES: 'STOFFKARTOTEK',
  EDIT_AUDIT_REPORT: 'EDITREVISJON',
  EDIT_INCIDENT: 'EDITHENDELSE',
  EDIT_INSPECTION_REPORT: 'EDITTILSYNSRAPPORT',
  EDIT_MEASURE: 'EDITTILTAK',
  EDIT_SAFETY_INSPECTION: 'EDITVERNERUNDE',
  EMPLOYEE_FORMS: 'EMPLOYEEFORMS',
  EMPLOYEE_INTERVIEW: 'MEDARBEIDERSAMTALE',
  EMPLOYER_ABSENCE: 'EDITFRAVAER',
  EMPLOYER_COMPETENCE: 'EDITKOMPETANSEOVERSIKT',
  EMPLOYER_EMPLOYEES: 'ANSATTE',
  EMPLOYER_FOLLOWUP_ABSENCE: 'OPPFOLGINGSYKEMELDTE',
  EMPLOYER_SURVEY: 'EMPLOYEE_SURVEY',
  EMPLOYER_VACATION: 'EDITFERIE',
  FILES: 'DOKUMENTER',
  HANDBOOK_ADMIN: 'PERSONALHANDBOK_ADMIN',
  HANDBOOK_READ: 'PERSONALHANDBOK_READ',
  HANDBOOK_SETTINGS: 'PERSONALHANDBOK_SETTINGS',
  INCIDENT_REPORT: 'RAPPORTER',
  JOB_SAFETY_ANALYSIS: 'JOB_SAFETY_ANALYSIS',
  LAWS: 'LOVVERK',
  MANUALS: 'MANUALER',
  MY_ABSENCE: 'FRAVAER',
  MY_COMPETENCE: 'MY_COMPETENCE',
  MY_HANDBOOKS: 'MY_HANDBOOKS',
  MY_HOURS: 'MINETIMER',
  MY_INCIDENTS: 'MINE_HENDELSER',
  MY_JOB_SAFETY_ANALYSIS: 'MY_JOB_SAFETY_ANALYSIS',
  MY_RESPONSE: 'MY_RESPONSE',
  MY_VACATION: 'FERIE',
  PREMISES: 'LOKALER',
  READ_DIRECTIVES: 'READ_DIRECTIVES',
  RESPONSE_TEMPLATE: 'EMPLOYEE_SURVEY_ADMIN',
  RISK_ASSESSMENT_REPORT: 'RISKASSESSMENTREPORT',
  RISK_CENTER: 'RISIKOSENTER',
  RISK_CRITERIA: 'AKSEPTKRITERIER',
  RISK_REPORT: 'RISIKORAPPORT',
  SAFETY_INSPECTIONS_EDIT: 'EDITVERNERUNDE',
  SETUP: 'OPPSETT',
  TIME_REGISTRATION: 'TIMEREGISTRERING',
  UNIT_INFORMATION: 'FORETAKSINFORMASJON',
  WORK_EQUIPMENT: 'ARBEIDSUTSTYR',
  WORK_SCHEDULE: 'ARBEIDSPLAN',
  SUPPORT_ACCOUNT: 'SUPPORTACCESS_ACCOUNTS',
  USER_MANAGEMENT: 'BRUKERSTYRING',
  PROJEKT_ADMIN: 'PROSJEKTADMIN'
}

<template>
  <div class="layout">
    <aside
      class="sidebar"
      v-if="uiStore.isSidebarVisible"
      :class="{ 'sidebar-visible': uiStore.isSidebarVisible }"
    >
      <SidebarMenu />
    </aside>
    <section class="main">
      <header
        class="header"
        v-if="uiStore.isTopbarVisible"
        :class="{ 'header-visible': uiStore.isTopbarVisible }"
      >
        <TopBar />
      </header>
      <div class="content">
        <RouterView />
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import TopBar from './components/layout/TopBar.vue'
import { useUi } from '@/stores/ui'

const uiStore = useUi()
</script>

<style scoped lang="scss">
.layout {
  display: flex;
  flex: 1;
  overflow: hidden;
  height: 100vh;

  .sidebar {
    width: 0;
    background-color: #f8f9fa;
    overflow-y: auto;
    transition: width 0.3s ease;

    &.sidebar-visible {
      @media (min-width: 960px) {
        width: 27.5rem;
      }
    }
  }

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .header {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;

    &.header-visible {
      height: auto;
      padding: 0 5rem;
      overflow: visible;
    }
  }

  .content {
    flex: 1;
    overflow-y: auto;
    position: relative;
  }
}
</style>

import { type App, reactive } from 'vue'
import {
  type EventMessage,
  EventMessageUtils,
  EventType,
  InteractionStatus,
  PublicClientApplication,
  type AccountInfo,
  type AuthenticationResult
} from '@azure/msal-browser'
import { deleteCookie } from '@/shared/utils/cookieUtils'
import {
  handleMonolithAuth,
  MonolithOperationType
} from '@/shared/utils/monolithAuthUtils'
export interface MsalState {
  instance?: PublicClientApplication
  inProgress: InteractionStatus
  accounts: AccountInfo[]
  token: {
    accessToken: string | undefined
    idToken: string | undefined
    proxy: string | undefined
  }
}

// Custom events for specific msal use cases
export enum CustomMsalEvent {
  LOGOUT = 'customMsalEvent:logout',
  PROXY_LOGIN = 'proxy:login'
}

type AccountIdentifiers = Partial<
  Pick<AccountInfo, 'homeAccountId' | 'localAccountId' | 'username'>
>

// Helper function to compare two arrays of account identifiers
export const accountArraysAreEqual = (
  arrayA: AccountIdentifiers[],
  arrayB: AccountIdentifiers[]
): boolean => {
  if (arrayA.length !== arrayB.length) return false

  return arrayA.every((elementA, index) => {
    const elementB = arrayB[index]
    return (
      elementB &&
      elementA.homeAccountId === elementB.homeAccountId &&
      elementA.localAccountId === elementB.localAccountId &&
      elementA.username === elementB.username
    )
  })
}

// Global reactive state
export const msalState = reactive<MsalState>({
  instance: undefined,
  inProgress: InteractionStatus.Startup,
  accounts: [],
  token: {
    accessToken: undefined,
    idToken: undefined,
    proxy: undefined
  }
})

export const msalPlugin = {
  install: async (_: App, msalInstance: PublicClientApplication) => {
    // Initialize the global state with the msalInstance
    msalState.instance = msalInstance
    await msalState.instance.initialize()

    msalState.accounts = msalInstance.getAllAccounts()

    const updateAccounts = () => {
      if (msalState.token.proxy) return

      const currentAccounts = msalInstance.getAllAccounts()
      if (!accountArraysAreEqual(currentAccounts, msalState.accounts)) {
        msalState.accounts = currentAccounts
      }
    }

    const updateToken = (token: AuthenticationResult) => {
      msalState.token.accessToken = token.accessToken
      msalState.token.idToken = token.idToken
    }

    // Msal event listener to handle internal msal events
    msalInstance.addEventCallback(async (message: EventMessage) => {
      const { eventType, payload } = message

      switch (eventType) {
        case EventType.LOGIN_SUCCESS: {
          const { idToken, account } = payload as AuthenticationResult

          msalInstance.setActiveAccount(account)
          updateAccounts()
          updateToken(payload as AuthenticationResult)

          await handleMonolithAuth(MonolithOperationType.LOGIN, idToken)
          break
        }
        case EventType.ACQUIRE_TOKEN_SUCCESS: {
          const { idToken } = payload as AuthenticationResult

          updateAccounts()
          updateToken(payload as AuthenticationResult)

          // Perform monolith login because we always want to have a valid session on page load
          await handleMonolithAuth(MonolithOperationType.LOGIN, idToken)
          break
        }
        case EventType.ACQUIRE_TOKEN_FAILURE: {
          msalInstance.loginRedirect()
          break
        }
        case EventType.HANDLE_REDIRECT_START: {
          break
        }
        case EventType.ACCOUNT_ADDED:
        case EventType.SSO_SILENT_SUCCESS:
        case EventType.HANDLE_REDIRECT_END: {
          updateAccounts()
          break
        }
      }

      const status = EventMessageUtils.getInteractionStatusFromEvent(
        message,
        msalState.inProgress
      )
      if (status !== null) {
        msalState.inProgress = status
      }
    })

    // Custom event listener to handle custom msal events
    document.addEventListener(CustomMsalEvent.LOGOUT, async () => {
      await handleMonolithAuth(MonolithOperationType.LOGOUT)
      if (msalState.token.proxy) {
        deleteCookie('token')
        window.close()
      } else
        await msalInstance.logoutRedirect({
          idTokenHint: msalState.token.idToken
        })
    })
    document.addEventListener(CustomMsalEvent.PROXY_LOGIN, (event: Event) => {
      const customEvent = event as CustomEvent<any>
      const { detail: token } = customEvent
      msalState.token.proxy = token
    })
  }
}

// Function to access the global state
export const useMsal = (): MsalState => {
  if (!msalState.instance) {
    throw new Error(
      'MSAL instance is not provided. Make sure you have installed the msalPlugin.'
    )
  }

  return msalState as MsalState
}

export namespace Amber.Command {
  export namespace V1 {
    export namespace RouteContext {
      export namespace Route {
        /**
         * Command to navigate to a route.
         * @event amber.command.v1.routecontext.route.navigateto
         * @property {string} path - The path to navigate to. Example: '/simple-example/sample'
         */
        export class NavigateTo {
          path: string

          constructor(path: string) {
            this.path = path
          }

          /**
           * Returns the command name as a string.
           * @returns {string} The command name.
           */
          static toString(): string {
            return 'amber.command.v1.routecontext.route.navigateto'
          }
        }

        /**
         * Command to replace the current state with a new state.
         * @event amber.command.v1.routecontext.replacestate
         * @property {string} path - The path to replace the current state with. Example: '/simple-example/sample'
         */
        export class ReplaceState {
          path: string

          constructor(path: string) {
            this.path = path
          }

          /**
           * Returns the command name as a string.
           * @returns {string} The command name.
           */
          static toString(): string {
            return 'amber.command.v1.routecontext.route.replacestate'
          }
        }
      }
    }

    export namespace FeatureFlag {
      /**
       * Command to set a feature flag.
       * @event amber.command.v1.featureflag.update
       * @property {string[]} features - String array of feature flags to set.
       */
      export class Update {
        features: string[]

        constructor(features: string[]) {
          this.features = features
        }

        /**
         * Returns the command name as a string.
         * @returns {string}
         */
        static toString(): string {
          return 'amber.command.v1.featureflag.update'
        }
      }
    }
  }
}

import type { RouteManifest } from '@hms-kontoret/amber.types'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const manifest: RouteManifest = {
  name: 'RiskCenter',
  route: {
    name: 'RiskCenter',
    path: '/risk-center',
    alias: ['/risk-management'],
    meta: {
      routeTitle: 'menu__title__risk_management',
      navMenuIcon: 'n-icon-warning-sign',
      includeInNavMenu: true
    }
  },
  children: [
    {
      name: 'JobSafetyAnalysis',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'JobSafetyAnalysis',
        path: 'risikosenter/jsa/jobsafetyanalysis_overview',
        meta: {
          routeTitle: 'menu__title__job_safety_analysis',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.JOB_SAFETY_ANALYSIS]
        }
      }
    },
    {
      name: 'RiskCriteria',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'RiskCriteria',
        path: 'risk-criteria',
        meta: {
          routeTitle: 'menu__title__risk_criteria',
          requiresAccessRights: [MENU_ACCESS.RISK_CRITERIA]
        }
      }
    },
    {
      name: 'RiskAssessmentsIFRAME',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'RiskAssessmentsIFRAME',
        path: 'risikosenter/ros/riskAssessmentsOverview',
        meta: {
          routeTitle: 'menu__title__risk_assessments',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.RISK_CENTER]
        }
      }
    },
    {
      name: 'RiskAssessments',
      remote: {
        module: 'risk-assessments'
      },
      route: {
        name: 'RiskAssessments',
        path: 'risk-assessments',
        meta: {
          routeTitle: 'menu__title__risk_assessments',
          requiresAccessRights: [MENU_ACCESS.RISK_CENTER]
        }
      }
    },
    {
      name: 'RiskAssesmentReports',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'RiskAssesmentReports',
        path: 'risikosenter/ros/riskAssessmentReportsOverview',
        meta: {
          routeTitle: 'menu__title__risk_assessment_reports',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.RISK_REPORT]
        }
      }
    }
  ]
}

export default manifest

import type { RouteLocationNormalized } from 'vue-router'

export namespace Amber.Event {
  export namespace V1 {
    export namespace RouteContext {
      export namespace Route {
        /**
         * Event fired when the user navigates.
         * @event amber.event.v1.routecontext.route.changed
         */
        export class Changed {
          to: RouteLocationNormalized
          from: RouteLocationNormalized

          constructor(
            to: RouteLocationNormalized,
            from: RouteLocationNormalized
          ) {
            this.to = to
            this.from = from
          }

          /**
           * Returns the event name as a string.
           * @returns {string} The event name.
           */
          static toString(): string {
            return 'amber.event.v1.routecontext.route.changed'
          }
        }
      }
    }

    export namespace AuthContext {
      export namespace User {
        export namespace Locale {
          /**
           * Event fired when the user locale changes.
           * @event amber.event.v1.authcontext.user.locale.updated
           */
          export class Updated {
            newLocale: string | null
            oldLocale: string | null

            constructor(newLocale: string | null, oldLocale: string | null) {
              this.newLocale = newLocale
              this.oldLocale = oldLocale
            }

            /**
             * Returns the event name as a string.
             * @returns {string} The event name.
             */
            static toString(): string {
              return 'amber.event.v1.authcontext.user.locale.updated'
            }
          }
        }
      }
    }

    export namespace FeatureFlagContext {
      export namespace Flags {
        /**
         * Event fired when the feature flags change.
         * @event amber.event.v1.featureflagcontext.flags.updated
         */
        export class Updated {
          newFlags: string[]

          constructor(newFlags: string[]) {
            this.newFlags = newFlags
          }

          /**
           * Returns the event name as a string.
           * @returns {string} The event name.
           */
          static toString(): string {
            return 'amber.event.v1.featureflagcontext.flags.updated'
          }
        }
      }
    }

    export namespace UiContext {
      export namespace Fullscreen {
        /**
         * Event fired when the fullscreen state changes.
         * @event amber.event.v1.uicontext.fullscreen.updated
         */
        export class Updated {
          isFullscreen: boolean

          constructor(isFullscreen: boolean) {
            this.isFullscreen = isFullscreen
          }

          /**
           * Returns the event name as a string.
           * @returns {string} The event name.
           */
          static toString(): string {
            return 'amber.event.v1.uicontext.fullscreen.updated'
          }
        }
      }
    }
  }
}

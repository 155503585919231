import type { App } from 'vue'
import type { Router } from 'vue-router'
import { createGtm, useGtm as _useGtm } from '@gtm-support/vue-gtm'

export const gtmPlugin = {
  install: (app: App, router: Router) => {
    const gtm = createGtm({
      id: import.meta.env.VITE_GTM_ID,
      vueRouter: router
    })

    app.use(gtm)
  }
}

export default gtmPlugin

export const useGtm = () => {
  const gtm = _useGtm()
  if (!gtm) throw new Error('GTM plugin is not installed')

  return gtm
}

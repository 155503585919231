import {
  updateContext as _updateContext,
  createContext
} from '@/plugins/launchDarklyPlugin'

/**
 * Updates the Launch Darkly context.
 */
export const updateContext = async (
  orgNr: string,
  accountId: number,
  userId: number,
  country: string
): Promise<void> => {
  await _updateContext(createContext(orgNr, accountId, userId, country))
}

export default {
  updateContext
}

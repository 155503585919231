import { Router } from 'vue-router'
import { Amber } from '@hms-kontoret/amber.types/Events'

export const registerRouteChangeEvents = (router: Router) => {
  router.afterEach((to, from) => {
    const event = new CustomEvent<Amber.Event.V1.RouteContext.Route.Changed>(
      Amber.Event.V1.RouteContext.Route.Changed.toString(),
      {
        detail: { to, from }
      }
    )

    if (to.fullPath !== from.fullPath) window.dispatchEvent(event)
  })
}

export default registerRouteChangeEvents

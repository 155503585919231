import { type RouteLocationNormalized, type RouteMeta } from 'vue-router'
import { useAuthUser } from '@/stores/authUser'
import { useFeatureFlags } from '@/stores/featureFlags'

export const hasRoutePermission = (to: RouteLocationNormalized): boolean => {
  const { isAnyMenuItemAccessible } = useAuthUser()
  const { isAnyFeatureEnabled } = useFeatureFlags()

  const { requiresAccessRights, requiresFeatureFlags } = to.meta as RouteMeta
  let hasPermission = true

  // Check if the user has access to the route based on access rights
  if (requiresAccessRights && !isAnyMenuItemAccessible(requiresAccessRights))
    hasPermission = false

  // Check if the user has access to the route based on feature flags
  if (requiresFeatureFlags && !isAnyFeatureEnabled(requiresFeatureFlags))
    hasPermission = false

  return hasPermission
}

// Parse a URL string into a route object.
// If the URL does not contain a query string, the returned object will only contain the path.
export const parseUrlToRouteObject = (url: string) => {
  const [path, queryString] = url.split('?')

  if (!queryString) return { path }

  const query = queryString.split('&').reduce(
    (acc, param) => {
      const [key, value] = param.split('=')
      if (key) {
        acc[key] = decodeURIComponent(value || '')
      }
      return acc
    },
    {} as Record<string, string | string[]>
  )

  return { path, query }
}

export const createGetRequest = async (url: string) => {
  if (shouldSkipMonolithActions()) return true

  try {
    const request = new Request(`${window.location.origin}${url}`, {
      headers: {
        'Content-Type': 'application/xhtml+xml',
        'Access-Control-Allow-Origin': '*'
      },
      method: 'GET'
    })

    const response = await fetch(request)

    if (response.ok) return true

    throw new Error(await response.text())
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const shouldSkipMonolithActions = (): boolean => {
  return import.meta.env.VITE_APP_ENVIRONMENT === 'local'
}

export const useMonolithService = () => {
  const login = async (token: string) => {
    const url = `/login_im.xhtml?token=${token}`
    await createGetRequest(url)
  }

  const logout = async () => {
    const url = '/logout_im.xhtml'
    await createGetRequest(url)
  }

  const keepAlive = async () => {
    const url = '/keep-alive.xhtml'
    await createGetRequest(url)
  }

  return {
    login,
    logout,
    keepAlive
  }
}

export * from './StoreStates'
export * from './RouteManifest'
export * from './Gtm'

// Import namespaces
// @ts-ignore: Allow export import in this context
import { Amber as AmberAPINamespace } from './API'
// @ts-ignore: Allow export import in this context
import { Amber as AmberEventNamespace } from './Events'
// @ts-ignore: Allow export import in this context
import { Amber as AmberCommandNamespace } from './Commands'

// Create the main Amber namespace and re-export the sub-namespaces
export namespace Amber {
  // @ts-ignore: Allow export import in this context
  export import API = AmberAPINamespace.API
  // @ts-ignore: Allow export import in this context
  export import Event = AmberEventNamespace.Event
  // @ts-ignore: Allow export import in this context
  export import Commands = AmberCommandNamespace.Command
}

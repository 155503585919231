import { Amber } from '@hms-kontoret/amber.types/Commands'
import { useFeatureFlags } from '@/stores/featureFlags'

export const registerFeatureFlagStoreEventHandlers = () => {
  window.addEventListener(Amber.Command.V1.FeatureFlag.Update.toString(), ((
    event: CustomEvent<Amber.Command.V1.FeatureFlag.Update>
  ) => {
    const { features } = event.detail
    useFeatureFlags().update(features)
  }) as EventListener)
}

export default registerFeatureFlagStoreEventHandlers

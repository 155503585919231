import { Router } from 'vue-router'
import { registerAuthUserStoreChangeEvent } from './authUser/registerEventEmitters'
import { registerFeatureFlagStoreChangeEvent } from './featureFlags/registerEventEmitters'
import { registerFeatureFlagStoreEventHandlers } from './featureFlags/registerEventHandlers'
import { registerUiStoreChangeEvent } from './ui/registerEventEmitters'
import {
  registerRemoteRoutesListener,
  registerRouteChangeEvents
} from './router'

/**
 * Registers all store change events.
 */
export const registerStoreEvents = () => {
  // Auth user
  registerAuthUserStoreChangeEvent()

  // Feature flags
  registerFeatureFlagStoreChangeEvent()
  registerFeatureFlagStoreEventHandlers()

  // UI
  registerUiStoreChangeEvent()
}

/**
 * Registers all route change events.
 */
export const registerRouteEvents = (router: Router) => {
  registerRemoteRoutesListener(router)
  registerRouteChangeEvents(router)
}

/**
 * Get cookie by name
 * @param name
 * @returns
 */
export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts?.pop()?.split(';')?.shift()
  return null
}

/**
 * Set cookie with name, value and days to expire
 * @param name
 * @param value
 * @param daysToExpire
 */
export const setCookie = (
  name: string,
  value: string,
  daysToExpire: number = 365
) => {
  const date = new Date()
  date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000)
  const expires = `expires=${date.toUTCString()}`
  document.cookie = `${name}=${value}; ${expires}; path=/`
}

/**
 * Delete cookie by name
 * @param name
 */
export const deleteCookie = (name: string) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;Max-Age=0;path=/;`
}

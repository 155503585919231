/**
 * User types from the monolith.
 * https://github.com/HMS-Kontoret/hmskontoret_java/blob/311b08cd198ac8bb6aee23026801242ca11721a8/src/main/java/no/hmskontoret/hmskontoret/brukere/UserTypes.java#L6
 *
 */
export enum UserType {
  ADMIN = 'ADMIN',
  PRIMARY_USER = 'HOVEDBRUKER',
  CORPORATE = 'KONSERN',
  NORMAL = 'NORMAL',
  DELETED = 'SLETTET',
  SUPPORT = 'SUPPORT'
}

/**
 * Represents the state of a user, containing their identification, role, and locale information.
 */
export type UserState = {
  /**
   * The unique identifier of the user.
   */
  id: number

  /**
   * The full name of the user.
   */
  fullName: string

  /**
   * The employee ID associated with the user.
   */
  employeeId: number

  /**
   * The type or role of the user.
   */
  type: UserType

  /**
   * The locale of the user, indicating their preferred language and region settings (e.g., 'en-US', 'nb-NO').
   */
  locale: string

  /**
   * Whether the user needs to complete an onboarding process before allowed to continue to the main application.
   */
  onboardingNeeded: boolean
}

/**
 * Represents the state of the company associated with the user, containing company details like contact information and address.
 */
export type CompanyState = {
  /**
   * The name of the company.
   */
  name: string

  /**
   * The phone number for contacting the company.
   */
  phone: string

  /**
   * The email address for contacting the company.
   */
  email: string

  /**
   * The organization's number, typically a unique identifier like a tax ID or registration number.
   */
  orgnr: string

  /**
   * The company's physical address.
   */
  address: string

  /**
   * The city where the company is located.
   */
  city: string

  /**
   * The postal code (zipcode) for the company's address.
   */
  zipcode: string

  /**
   * The industry code associated with the company.
   */
  industryCode: string
}

/**
 * Represents the state of an account associated with the user, containing details like the account's country and supported locales.
 */
export type AccountState = {
  /**
   * The unique identifier of the account.
   */
  id: number

  /**
   * The country associated with the account (e.g., 'NO' for Norway).
   */
  country: string

  /**
   * An array of locales supported by this account (e.g., ['en-GB', 'nb-NO']).
   */
  supportedLocales: string[]

  /**
   * The date when the initial onboarding process for the account was completed.
   */
  onboardingCompletedDate: Date | null
}

import type { RouteManifest } from '@hms-kontoret/amber.types'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const manifest: RouteManifest = {
  name: 'Settings',
  route: {
    name: 'Settings',
    path: '/settings',
    meta: {
      routeTitle: 'menu__title__settings',
      navMenuIcon: 'n-icon-cogwheel',
      includeInNavMenu: true
    }
  },
  children: [
    {
      name: 'AccountSettings',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'AccountSettings',
        path: 'kontoinnstillinger/kontoinnstillinger',
        meta: {
          routeTitle: 'menu__title__account_settings',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.ACCOUNT_SETTINGS]
        }
      }
    },
    {
      name: 'Privileges',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Privileges',
        path: 'kontrollpanel/brukeroversikt',
        meta: {
          routeTitle: 'menu__title__privileges',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.USER_MANAGEMENT]
        }
      }
    },
    {
      name: 'Projects',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Projects',
        path: 'projects',
        meta: {
          routeTitle: 'menu__title__projects',
          requiresAccessRights: [MENU_ACCESS.PROJEKT_ADMIN]
        }
      }
    },
    {
      name: 'Setup',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Setup',
        path: 'oppsett/oppsett',
        meta: {
          routeTitle: 'menu__title__setup',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.SETUP]
        }
      }
    },
    {
      name: 'GroupReports',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'GroupReports',
        path: 'konsern/konsernkontoer',
        meta: {
          routeTitle: 'menu__title__group_accounts',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.CORPORATE]
        }
      }
    },
    {
      name: 'CustomerSupport',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'CustomerSupport',
        path: 'control-panel/customer-support',
        meta: {
          routeTitle: 'meta__title__customer_support',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.SUPPORT_ACCOUNT]
        }
      }
    },
    {
      name: 'AvonovaAccess',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'AvonovaAccess',
        path: 'control-panel/avonova-access',
        meta: {
          routeTitle: 'meta__title__account_admin',
          isMonolith: true
        }
      }
    },
    {
      name: 'AnnualReport',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'AnnualReport',
        path: 'control-panel/annual-report',
        meta: {
          routeTitle: 'meta__title__annual_report',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.SUPPORT_ACCOUNT]
        }
      }
    }
  ]
}

export default manifest

import { createRoute } from '@/router/routeHelpers'

export default [
  createRoute({
    path: '/',
    alias: ['/app', ''],
    name: 'Home',
    redirect: '/index'
  }),
  createRoute({
    path: '/not-found',
    name: 'Not Found',
    component: () => import(`@/views/NotFound.vue`)
  }),
  createRoute({
    path: '/app/login',
    name: 'Login',
    redirect: '/'
  }),
  createRoute({
    path: '/app/logout',
    name: 'Logout',
    redirect: '/'
  })
]

import { watch } from 'vue'
import { useUi } from '../../stores/ui/index'
import { Amber } from '@hms-kontoret/amber.types/Events'
/**
 * Registers a watcher on the user store to emit a custom event
 * whenever the ui object changes.
 */
export const registerUiStoreChangeEvent = () => {
  const uiStore = useUi()

  watch(
    () => uiStore.isFullscreen,
    newFullscreenState => {
      const event =
        new CustomEvent<Amber.Event.V1.UiContext.Fullscreen.Updated>(
          Amber.Event.V1.UiContext.Fullscreen.Updated.toString(),
          {
            detail: { isFullscreen: newFullscreenState }
          }
        )

      window.dispatchEvent(event)
    },
    { immediate: true }
  )
}

import type {
  MFEManifest,
  Remote,
  RouteManifest
} from '@hms-kontoret/amber.types'
import IncidentRegistry from '@/manifest/routeManifests/incidentRegistry'
import Directives from '@/manifest/routeManifests/directives'
import UnitInformation from '@/manifest/routeManifests/company'
import Employer from '@/manifest/routeManifests/employer'
import RiskCenter from '@/manifest/routeManifests/riskCenter'
import CardFile from '@/manifest/routeManifests/cardFile'
import Settings from '@/manifest/routeManifests/settings'
import Manuals from '@/manifest/routeManifests/manuals'
import Employee from '@/manifest/routeManifests/employee'
import AdminTools from '@/manifest/routeManifests/adminTools'
import SimpleExample from '@/manifest/routeManifests/simpleExample'
const manifest: Array<RouteManifest> = [
  Employee,
  UnitInformation,
  Employer,
  Directives,
  RiskCenter,
  IncidentRegistry,
  CardFile,
  Settings,
  Manuals,
  AdminTools
]

// Fallback route for unknown routes
const baseManifests: Array<RouteManifest> = [
  {
    name: 'Monolith',
    route: {
      name: 'Monolith',
      path: '/',
      meta: {
        routeTitle: 'menu__title__monolith'
      }
    },
    children: [
      {
        name: 'Dashboard',
        remote: {
          module: 'iframefusion'
        },
        route: {
          name: 'Dashboard',
          path: 'index',
          meta: {
            routeTitle: 'menu__title__dashboard'
          }
        }
      },
      {
        name: 'StartPage',
        remote: {
          module: 'iframefusion'
        },
        route: {
          name: 'StartPage',
          path: 'services',
          meta: {
            routeTitle: 'menu__title__start_page'
          }
        }
      },
      {
        name: 'FallbackChild',
        remote: {
          module: 'iframefusion'
        },
        route: {
          name: 'FallbackChild',
          path: ':pathMatch(.*)*',
          meta: {
            routeTitle: 'Fallback'
          }
        }
      }
    ]
  }
]

export const getRouteManifests = (): Array<RouteManifest> => {
  const isLocal = import.meta.env.VITE_APP_ENVIRONMENT === 'local'

  return isLocal
    ? // Add the SimpleExample manifest only if the environment is local(e.g in github workflow)
      [...manifest, SimpleExample, ...baseManifests]
    : [...manifest, ...baseManifests]
}

export const findRouteManifest = (
  parentName: string,
  childName: string
): MFEManifest | null => {
  const manifest = getRouteManifests()
  const parent = manifest.find(x => x.name === parentName)
  if (!parent) return null

  const child = parent.children.find(mfe => mfe.name === childName)
  if (!child) return null

  return child
}

/**
 * Retrieves all unique remotes from the route manifests.
 * @returns {Array<Remote>} Array of unique `Remote` objects.
 */
export const getRemotes = (): Array<Remote> => {
  const manifests = getRouteManifests()

  // Extract all remote definitions from the manifests
  const remotes = manifests.flatMap(
    manifest =>
      manifest.children
        ?.map(child => child.remote)
        .filter((remote): remote is Remote => Boolean(remote))
  )

  // Deduplicate remotes by converting to a Map keyed by `module`
  const uniqueRemotes = Array.from(
    new Map(remotes.map(remote => [remote.module, remote])).values()
  )

  return uniqueRemotes
}

export default getRouteManifests()

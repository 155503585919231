import type { RouteManifest } from '@hms-kontoret/amber.types'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const manifest: RouteManifest = {
  name: 'AdminTools',
  route: {
    name: 'AdminTools',
    path: '/admin-tools',
    alias: ['/admintools'],
    meta: {
      routeTitle: 'menu__title__admin_tools',
      navMenuIcon: 'n-icon-settings',
      includeInNavMenu: true,
      requiresAccessRights: [MENU_ACCESS.ADMIN]
    }
  },
  children: [
    {
      name: 'AdminAccountOverview',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'AdminAccountOverview',
        path: 'overview',
        meta: {
          routeTitle: 'menu__title__admin_account_overview',
          navMenuIcon: 'n-icon-user'
        }
      }
    },
    {
      name: 'AdminAccountSupportAccess',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'AdminAccountSupportAccess',
        path: 'support-access',
        alias: ['supportaccess'],
        meta: {
          routeTitle: 'menu__title__admin_account_support_access',
          navMenuIcon: 'n-icon-user'
        }
      }
    },
    {
      name: 'AdminAccountSupportUsers',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'AdminAccountSupportUsers',
        path: 'support-users',
        alias: ['supportusers'],
        meta: {
          routeTitle: 'menu__title__admin_account_support_users',
          navMenuIcon: 'n-icon-user'
        }
      }
    }
  ]
}

export default manifest

import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { localize } from './localization'
import {
  type InitialData,
  UserType as UserTypeDto
} from '@/services/accountsService/dto'
import {
  type AccountState,
  type CompanyState,
  type UserState,
  type GtmUserType,
  UserType,
  GtmEvent
} from '@hms-kontoret/amber.types'

import { useUserService } from '@/services/userService'
import { updateDataLayer } from '@/shared/utils/analyticsUtils'
import { updateContext } from '@/shared/utils/featureFlagsUtils'

// Map UserTypeDto to UserType
const mapToUserType = (userType: UserTypeDto): UserType => {
  const mapping: Record<UserTypeDto, UserType> = {
    [UserTypeDto.ADMIN]: UserType.ADMIN,
    [UserTypeDto.HOVEDBRUKER]: UserType.PRIMARY_USER,
    [UserTypeDto.KONSERN]: UserType.CORPORATE,
    [UserTypeDto.NORMAL]: UserType.NORMAL,
    [UserTypeDto.SLETTET]: UserType.DELETED,
    [UserTypeDto.SUPPORT]: UserType.SUPPORT
  }

  return (
    mapping[userType] ??
    (() => {
      throw new Error(`Unhandled UserTypeDto: ${userType}`)
    })()
  )
}

export const useAuthUser = defineStore('authUser', () => {
  // State
  const initialDataFetched = ref(false)
  const account = ref<AccountState>({} as AccountState)
  const company = ref<CompanyState>({} as CompanyState)
  const user = ref<UserState>({} as UserState)
  const accessibleMenuItems = ref<string[]>([])

  // Getters
  const userId = computed(() => user.value?.id || null)
  const userEmployeeId = computed(() => user.value?.employeeId || null)
  const userType = computed(() => user.value?.type || null)
  const userLocale = computed(() => user.value?.locale || null)
  const userFullName = computed(() => user.value?.fullName || null)

  const accountCountry = computed(() => account.value?.country || null)
  const accountId = computed(() => account.value?.id || null)

  const companyName = computed(() => company.value?.name || null)
  const companyPhone = computed(() => company.value?.phone || null)
  const companyEmail = computed(() => company.value?.email || null)
  const companyOrgnr = computed(() => company.value?.orgnr || null)
  const companyAddress = computed(() => company.value?.address || null)
  const companyCity = computed(() => company.value?.city || null)
  const companyZipcode = computed(() => company.value?.zipcode || null)

  const isMenuItemAccessible = (menuItem: string) =>
    accessibleMenuItems.value.includes(menuItem)

  const isAnyMenuItemAccessible = (menuItems: string[]) =>
    menuItems.some(menuItem => accessibleMenuItems.value.includes(menuItem))

  // Actions
  const updateWithInitialData = (initialData: InitialData) => {
    // Update state with initial data
    account.value = {
      id: initialData.account_id,
      country: initialData.accountCountry,
      supportedLocales: initialData.accountLocales,
      onboardingCompletedDate: initialData.onboarding_completed_date
    }

    company.value = {
      name: initialData.companyname,
      phone: initialData.company_phone,
      email: initialData.company_email,
      orgnr: initialData.company_orgnr,
      address: initialData.company_address,
      city: initialData.company_city,
      zipcode: initialData.company_zipcode,
      industryCode: initialData.industry_codes
    }

    user.value = {
      id: initialData.user_id,
      fullName: initialData.loggedinusername,
      employeeId: initialData.employee_id,
      type: mapToUserType(initialData.userType),
      locale: initialData.userLocale,
      onboardingNeeded: initialData.onboardingNeeded
    }

    // Updating the list of accessible menu items
    accessibleMenuItems.value = initialData.menuaccess

    // Set Inital Data Fetched to true to indicate that the data has been fetched
    initialDataFetched.value = true

    // Localize the app
    localize(userLocale.value, accountCountry.value)

    // Update GTM data layer
    updateDataLayer({
      event: GtmEvent.INIT_DATA,

      userId: initialData.user_id,
      dlUserType: initialData.gtmUserType as GtmUserType,
      locale: initialData.userLocale,
      language: initialData.userLocale.split('-')[0],
      fullName: initialData.loggedinusername,
      companyName: initialData.companyname,
      companyEmail: initialData.company_email,
      companyPhone: initialData.company_phone,
      topLevelMenuName: '',

      dlAccountId: initialData.account_id,
      dlOrgNumber: initialData.company_orgnr,
      dlAccountCountry: initialData.accountCountry,
      dlAccountNumUsersAllowed: initialData.number_account_users_allowed,
      dlOnboardingCompleteDate:
        initialData.onboarding_completed_date?.toString() ?? null,
      dlNaeringskode1: initialData.industry_codes,
      dlAccountAdminTag: initialData.account_admin_tag,
      dlTestAccount: initialData.test_account,

      dlAssist: initialData.menuaccess.includes('ASSIST'),
      dlAssistHealth: initialData.menuaccess.includes('ASSISTHEALTH'),
      dlAssistHealthInsurance: initialData.menuaccess.includes('INSURANCE'),
      dlEntireBody: initialData.menuaccess.includes('ENTIREBODY')
    })

    // Update LaunchDarkly context
    updateContext(
      initialData.company_orgnr,
      initialData.account_id,
      initialData.user_id,
      initialData.accountCountry
    )
  }

  const isValidLocale = (locale: string) => {
    return account.value.supportedLocales.includes(locale)
  }

  const updateLocale = async (locale: string) => {
    if (!user.value || !isValidLocale(locale)) throw new Error('Invalid locale')

    await useUserService().updateLocale(locale)
    user.value.locale = locale
    localize(userLocale.value, accountCountry.value)
  }

  return {
    // State
    initialDataFetched,
    account,
    company,
    user,
    accessibleMenuItems,

    // Getters
    accountCountry,
    accountId,

    companyName,
    companyPhone,
    companyEmail,
    companyOrgnr,
    companyAddress,
    companyCity,
    companyZipcode,

    userId,
    userEmployeeId,
    userType,
    userLocale,
    userFullName,

    isMenuItemAccessible,
    isAnyMenuItemAccessible,

    isValidLocale,

    // Actions
    updateWithInitialData,
    updateLocale
  }
})

import type { RouteManifest } from '@hms-kontoret/amber.types'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const manifest: RouteManifest = {
  name: 'Directives',
  route: {
    name: 'Directives',
    path: '/directives',
    meta: {
      routeTitle: 'menu__title__directives',
      navMenuIcon: 'n-icon-file',
      includeInNavMenu: true
    }
  },
  children: [
    {
      name: 'Handbooks',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Handbooks',
        path: 'handbok/handbok',
        meta: {
          routeTitle: 'menu__title__handbooks',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.MY_HANDBOOKS]
        }
      }
    },
    {
      name: 'Instructions',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Instructions',
        path: 'styrendedokumenter/styrendeDokumenter',
        meta: {
          routeTitle: 'menu__title__instructions',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.READ_DIRECTIVES]
        }
      }
    }
  ]
}

export default manifest

import { watch } from 'vue'
import { useAuthUser } from '@/stores/authUser'
import { Amber } from '@hms-kontoret/amber.types/Events'
/**
 * Registers a watcher on the user store to emit a custom event
 * whenever the user object changes.
 */
export const registerAuthUserStoreChangeEvent = () => {
  const authUserStore = useAuthUser()

  watch(
    () => authUserStore.userLocale,
    (newLocale, oldLocale) => {
      const localeChangedEvent =
        new CustomEvent<Amber.Event.V1.AuthContext.User.Locale.Updated>(
          Amber.Event.V1.AuthContext.User.Locale.Updated.toString(),
          {
            detail: { newLocale, oldLocale }
          }
        )

      window.dispatchEvent(localeChangedEvent)
    }
  )
}

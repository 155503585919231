import type { RouteManifest } from '@hms-kontoret/amber.types'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const manifest: RouteManifest = {
  name: 'Employee',
  route: {
    name: 'Employee',
    path: '/employee',
    alias: ['/personnel'],
    meta: {
      routeTitle: 'menu__title__employee',
      navMenuIcon: 'n-icon-user',
      includeInNavMenu: true
    }
  },
  children: [
    {
      name: 'MyVacation',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'MyVacation',
        path: 'vacation',
        meta: {
          routeTitle: 'menu__title__my_vacation',
          navMenuIcon: 'n-icon-star',
          requiresAccessRights: [MENU_ACCESS.MY_VACATION]
        }
      }
    },
    {
      name: 'MyAbsence',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'MyAbsence',
        path: 'absence',
        meta: {
          routeTitle: 'menu__title__my_absence',
          navMenuIcon: 'n-icon-menu-8',
          requiresAccessRights: [MENU_ACCESS.MY_ABSENCE]
        }
      }
    },
    {
      name: 'MyIncidents',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'MyIncidents',
        path: 'incidents',
        alias: ['my-incidents'],
        meta: {
          routeTitle: 'menu__title__my_incidents',
          navMenuIcon: 'n-icon-warning-sign',
          requiresAccessRights: [MENU_ACCESS.MY_INCIDENTS]
        }
      }
    },
    {
      name: 'MyJobSafetyAnalysis',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'MyJobSafetyAnalysis',
        path: 'risikosenter/jsa/my_jobsafetyanalysis_overview',
        meta: {
          routeTitle: 'menu__title__my_job_safety_analysis',
          navMenuIcon: 'n-icon-pen',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.MY_JOB_SAFETY_ANALYSIS]
        }
      }
    },
    {
      name: 'MyHours',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'MyHours',
        path: 'hours',
        alias: ['my-hours'],
        meta: {
          routeTitle: 'menu__title__my_hours',
          navMenuIcon: 'n-icon-clock',
          requiresAccessRights: [MENU_ACCESS.MY_HOURS]
        }
      }
    },
    {
      name: 'MyCompetence',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'MyCompetence',
        path: 'competence',
        meta: {
          routeTitle: 'menu__title__my_competence',
          navMenuIcon: 'n-icon-e-remove',
          requiresAccessRights: [MENU_ACCESS.MY_COMPETENCE]
        }
      }
    },
    {
      name: 'MyResponse',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'MyResponse',
        path: 'response',
        alias: ['my-response/'],
        meta: {
          routeTitle: 'menu__title__my_respons',
          navMenuIcon: 'n-icon-e-remove',
          requiresAccessRights: [MENU_ACCESS.MY_RESPONSE]
        }
      }
    },
    {
      name: 'MyInstructions',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'MyInstructions',
        path: 'styrendedokumenter/styrendeDokumenter_groupedbycategory',
        meta: {
          routeTitle: 'menu__title__my_instructions',
          navMenuIcon: 'n-icon-e-remove',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.READ_DIRECTIVES]
        }
      }
    }
  ]
}

export default manifest

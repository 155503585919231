import { MENU_ACCESS } from '@/shared/constants/menuAccess'
import type { RouteManifest } from '@hms-kontoret/amber.types'

const manifest: RouteManifest = {
  name: 'IncidentRegistry',
  route: {
    name: 'IncidentRegistry',
    path: '/incident-registry',
    alias: ['/incidentregistry'],
    meta: {
      routeTitle: 'menu__title__incident_registry',
      navMenuIcon: 'n-icon-list',
      includeInNavMenu: true,
      requiresAccessRights: [MENU_ACCESS.EDIT_INCIDENT]
    }
  },
  children: [
    {
      name: 'ActionPlan',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'ActionPlan',
        path: 'action-plan',
        alias: ['actionplan'],
        meta: {
          routeTitle: 'menu__title__action_plan'
        }
      }
    },
    {
      name: 'SafetyInspections',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'SafetyInspections',
        path: 'safety-inspections',
        meta: {
          routeTitle: 'menu__title__safety_inspections',
          requiresAccessRights: [MENU_ACCESS.SAFETY_INSPECTIONS_EDIT]
        }
      }
    },
    {
      name: 'Incident',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Incident',
        path: 'incidents',
        meta: {
          routeTitle: 'menu__title__incidents'
        }
      }
    },
    {
      name: 'Inspections',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Inspections',
        path: 'inspections',
        meta: {
          routeTitle: 'menu__title__inspections'
        }
      }
    },
    {
      name: 'AuditReports',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'AuditReports',
        path: 'hendelsesregister/revisjon/revisions',
        meta: {
          routeTitle: 'menu__title__audit_reports',
          isMonolith: true
        }
      }
    },
    {
      name: 'Reports',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Reports',
        path: 'hendelsesregister/rapporter/oversikt',
        meta: {
          routeTitle: 'menu__title__reports',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.INCIDENT_REPORT]
        }
      }
    },
    {
      name: 'GroupReports',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'GroupReports',
        path: 'konsern/konsernrapporter',
        meta: {
          routeTitle: 'menu__title__group_reports',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.CORPORATE_REPORT]
        }
      }
    }
  ]
}

export default manifest

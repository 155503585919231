<template>
  <header>
    <div class="left-section">
      <div class="wrapper">
        <HamburgerSidebarMenu targetSelector="#app > div > section > div" />
      </div>
    </div>
    <div class="middle-section">
      <div class="wrapper">
        <AvonovaLogo />
      </div>
    </div>
    <div class="right-section">
      <div class="wrapper">
        <ProfileMenu />
        <HelpMenu />
        <TimeRecorder v-if="isTimeRecorderAvailable" />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import AvonovaLogo from '../../assets/icons/avonova-logo.svg'
import { useAuthUser } from '../../stores/authUser/index'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const isTimeRecorderAvailable = computed(() => {
  const store = useAuthUser()
  return store.isMenuItemAccessible(MENU_ACCESS.MY_HOURS)
})
</script>

<style scoped>
header {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  line-height: 1.5;
  height: 5rem;
  max-height: 100vh;
  place-items: center;
}

header .left-section > .wrapper {
  display: flex;

  @media (min-width: 960px) {
    display: none;
  }
}

header .middle-section > .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media (min-width: 960px) {
    display: none;
  }
}

header .right-section > .wrapper {
  display: none;

  @media (min-width: 960px) {
    display: flex;
    gap: 0.5rem;
  }
}
</style>

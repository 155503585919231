import { watch } from 'vue'
import { useFeatureFlags } from '@/stores/featureFlags'
import { Amber } from '@hms-kontoret/amber.types/Events'

/**
 * Registers a watcher on the user store to emit a custom event
 * whenever the feature flag object changes.
 */
export const registerFeatureFlagStoreChangeEvent = () => {
  const featureFlagStore = useFeatureFlags()

  watch(
    () => featureFlagStore.featureFlags,
    newFlags => {
      const event =
        new CustomEvent<Amber.Event.V1.FeatureFlagContext.Flags.Updated>(
          Amber.Event.V1.FeatureFlagContext.Flags.Updated.toString(),
          {
            detail: { newFlags }
          }
        )

      window.dispatchEvent(event)
    },
    { deep: true, immediate: true }
  )
}

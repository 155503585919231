import {
  documentToHtmlString,
  type Next
} from '@contentful/rich-text-html-renderer'
import {
  BLOCKS,
  MARKS,
  type Block,
  type Inline
} from '@contentful/rich-text-types'

export const defaultLanguage = 'nb-NO'
export const languages = [
  'nb-NO',
  'nb-EN',
  'nb-SE',
  'nb-PL',
  'sv-SE',
  'sv-EN',
  'sv-NO',
  'fi-FI',
  'fi-SE'
]
export const languagesWithoutDefault = languages.filter(t => {
  return t !== defaultLanguage
})
export const renderOptions = {
  renderMark: {
    [MARKS.BOLD]: (text: string) => `<b>${text}</b>`
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: Block | Inline, next: Next) =>
      `<p>${next(node.content)}</p>`,
    [BLOCKS.UL_LIST]: (node: Block | Inline, next: Next) =>
      `<ul>${next(node.content)}</ul>`,
    [BLOCKS.LIST_ITEM]: (node: Block | Inline, next: Next) =>
      `<li>${next(node.content)}</li>`
  }
}

export const renderDocumentNode = (content: any) =>
  documentToHtmlString(content, renderOptions)

import { useI18n } from '@/plugins/i18nPlugin'
import { setCookie } from '@/shared/utils/cookieUtils'

export const DEFAULT_LOCALE = 'nb-NO'
export const ACCOUNT_COUNTRY = {
  NORWAY: 'NO',
  SWEDEN: 'SE',
  FINLAND: 'FI'
}

interface LanguageMap {
  [key: string]: string
}

interface CountryLanguageMap {
  [key: string]: LanguageMap
}

export const mapISOToi18nLegacyLanguage = (
  isoLocale: string,
  countryCode: string
) => {
  // Map to legacy language codes because contentful uses them
  const countryLanguageMap: CountryLanguageMap = {
    [ACCOUNT_COUNTRY.NORWAY]: {
      'nb-NO': 'nb-NO',
      'en-GB': 'nb-EN',
      'pl-PL': 'nb-PL'
    },
    [ACCOUNT_COUNTRY.SWEDEN]: {
      'en-GB': 'sv-EN',
      'sv-SE': 'sv-SE'
    },
    [ACCOUNT_COUNTRY.FINLAND]: {
      'fi-FI': 'fi-FI',
      'sv-SE': 'fi-SE'
    }
  }
  return (
    countryLanguageMap[countryCode.toUpperCase()][isoLocale] || DEFAULT_LOCALE
  )
}

export const seti18nLanguage = (locale: string, countryCode: string) => {
  const languageCode = mapISOToi18nLegacyLanguage(locale, countryCode)

  const i18n = useI18n()
  // @ts-ignore: This line ignores the TypeScript error
  i18n.global.locale.value = languageCode
}

export const setMarkupLanguageAttribute = (locale: string) => {
  document.documentElement.setAttribute('lang', locale)
}

export const setLanguageLocale = (locale: string, countryCode: string) => {
  seti18nLanguage(locale, countryCode)
  setMarkupLanguageAttribute(locale)
  setLangCookie(locale)
}

export const setLangCookie = (locale: string) => {
  setCookie('lang', locale)
}

export const localize = (locale: string | null, countryCode: string | null) => {
  if (!locale || !countryCode)
    throw new Error('Locale or country code is missing')

  // Locale is in ISO format, e.g. nb-NO
  setLanguageLocale(locale, countryCode)
}

import type { InitialData } from '@/services/accountsService/dto'
import { createGetRequest } from '@/shared/requestHandler'

export const useAccountService = () => {
  const getInitData = async () => {
    const url = '/common/api/accounts/init'
    return await createGetRequest<InitialData>(url)
  }

  return {
    getInitData
  }
}

import { acquireAuthenticationToken } from './utils/authUtils'
const BASE_URL = import.meta.env.VITE_API_URL

/**
 * Creates headers for a fetch request with the current user's ID token.
 * @returns Headers for the fetch request.
 */
export const createHeaders = async (
  token: string | undefined = undefined
): Promise<HeadersInit> => {
  if (token === undefined) {
    const { idToken } = await acquireAuthenticationToken()
    token = idToken
  }

  return {
    'Authorization': `Token ${token}`,
    'Content-Type': 'application/json'
  }
}

/**
 * Handles the response from a fetch request.
 * @param response Response object from the fetch request.
 * @returns Data formatted as the specified type in JSON, or undefined if no content.
 */
export const handleResponse = async <S>(
  response: Response
): Promise<S | undefined> => {
  if (response.ok)
    return response.status === 204 ? undefined : await response.json()

  throw new Error(await response.text())
}

/**
 * Sends a PUT request to the specified endpoint with the given data.
 * @param endpointPath Path for the current service and endpoint.
 * @param data Data to be sent as the body of the PUT request.
 * @returns Data formatted as the specified type in JSON, or undefined if no content.
 */
export const createPutRequest = async <S>(url: string, body: any) => {
  try {
    const request = new Request(`${BASE_URL}${url}`, {
      headers: await createHeaders(),
      method: 'PUT',
      body: JSON.stringify(body)
    })

    const response = await fetch(request)
    return await handleResponse<S>(response)
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Sends a POST request to the specified endpoint with the given data.
 * @param endpointPath Path for the current service and endpoint.
 * @param data Data to be sent as the body of the POST request.
 * @returns Data formatted as the specified type in JSON, or undefined if no content.
 */
export const createPostRequest = async <S>(url: string, body: any) => {
  try {
    const request = new Request(`${BASE_URL}${url}`, {
      headers: await createHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    })

    const response = await fetch(request)
    return await handleResponse<S>(response)
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Sends a DELETE request to the specified endpoint.
 * @param endpointPath Path for the current service and endpoint.
 * @returns Data formatted as the specified type in JSON, or undefined if no content.
 */
export const createDeleteRequest = async <S>(url: string) => {
  try {
    const request = new Request(`${BASE_URL}${url}`, {
      headers: await createHeaders(),
      method: 'DELETE'
    })

    const response = await fetch(request)
    return await handleResponse<S>(response)
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Sends a GET request to the specified endpoint and returns data formatted as the specified type.
 * @param endpointPath Path for the current service and endpoint.
 * @returns Data formatted as the specified type in JSON.
 */
export const createGetRequest = async <S>(url: string) => {
  try {
    const request = new Request(`${BASE_URL}${url}`, {
      headers: await createHeaders(),
      method: 'GET'
    })

    const response = await fetch(request)
    return await handleResponse<S>(response)
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Sends a GET request to the specified endpoint and returns the response as text.
 * @param endpointPath Path for the current service and endpoint.
 * @returns Text from the endpoint if successful.
 */
export const createGetTextRequest = async (url: string) => {
  try {
    const request = new Request(`${BASE_URL}${url}`, {
      headers: await createHeaders(),
      method: 'GET'
    })

    const response = await fetch(request)
    return await handleResponse<string>(response)
  } catch (error) {
    console.error(error)
    throw error
  }
}

import type { RouteManifest } from '@hms-kontoret/amber.types'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const manifest: RouteManifest = {
  name: 'CardFile',
  route: {
    name: 'CardFile',
    path: '/card-file',
    meta: {
      routeTitle: 'menu__title__card_file',
      navMenuIcon: 'n-icon-warning-sign',
      includeInNavMenu: true
    }
  },
  children: [
    {
      name: 'DangerousSubstances',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'DangerousSubstances',
        path: 'dangeroussubstances',
        meta: {
          routeTitle: 'menu__title__dangerous_substances',
          externalLink: 'https://chemicals.avonova.com',
          requiresAccessRights: [MENU_ACCESS.DANGEROUS_SUBSTANCES]
        }
      }
    },
    {
      name: 'WorkEquipment',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'WorkEquipment',
        path: 'arbeidsutstyr/arbeidsutstyr',
        meta: {
          routeTitle: 'menu__title__work_equipment',
          isMonolith: true,
          requiresAccessRights: [MENU_ACCESS.WORK_EQUIPMENT]
        }
      }
    },
    {
      name: 'Agreements',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Agreements',
        path: 'agreements',
        meta: {
          routeTitle: 'menu__title__agreements',
          requiresAccessRights: [MENU_ACCESS.AGREEMENTS]
        }
      }
    },
    {
      name: 'LawsAndRegulations',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'LawsAndRegulations',
        path: 'laws',
        meta: {
          routeTitle: 'menu__title__laws_and_regulations',
          requiresAccessRights: [MENU_ACCESS.LAWS]
        }
      }
    },
    {
      name: 'Files',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Files',
        path: 'files',
        meta: {
          routeTitle: 'menu__title__files',
          requiresAccessRights: [MENU_ACCESS.FILES]
        }
      }
    },
    {
      name: 'Contacts',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Contacts',
        path: 'contacts',
        meta: {
          routeTitle: 'menu__title__contacts',
          requiresAccessRights: [MENU_ACCESS.CONTACTS]
        }
      }
    }
  ]
}

export default manifest

export type InitialData = {
  userLanguage: string
  companyname: string
  company_phone: string
  company_email: string
  company_orgnr: string
  company_address: string
  company_city: string
  company_zipcode: string
  loggedinusername: string
  menuaccess: string[]
  showWelcomeDashboard: boolean
  industry_codes: string
  account_id: number
  user_id: number
  employee_id: number
  account_admin_tag: string
  test_account: boolean
  number_account_users_allowed: number
  userType: UserType
  template_account: boolean
  onboardingNeeded: boolean
  onboarding_completed_date: Date | null
  sub_accounts: any[]
  corporateAccount: boolean
  accountCountry: string
  accountLanguage: string
  accountLocales: string[]
  userLocale: string
  gtmUserType: string
}

export enum UserType {
  ADMIN = 'ADMIN',
  HOVEDBRUKER = 'HOVEDBRUKER',
  KONSERN = 'KONSERN',
  NORMAL = 'NORMAL',
  SLETTET = 'SLETTET',
  SUPPORT = 'SUPPORT'
}

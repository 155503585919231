import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useUi = defineStore('ui', () => {
  // State
  const isTopbarVisible = ref(true)
  const isSidebarVisible = ref(true)

  // Getter
  const isFullscreen = computed((): boolean => {
    return !isTopbarVisible.value && !isSidebarVisible.value
  })

  // Actions
  const enterFullscreen = () => {
    isTopbarVisible.value = false
    isSidebarVisible.value = false
  }

  const exitFullscreen = () => {
    isTopbarVisible.value = true
    isSidebarVisible.value = true
  }

  return {
    // State
    isTopbarVisible,
    isSidebarVisible,

    // Getter
    isFullscreen,

    // Actions
    enterFullscreen,
    exitFullscreen
  }
})

import { createI18n, type I18n } from 'vue-i18n'
import { updateMessages } from '@/contentful/handler'

export const DEFAULT_CONTENTFUL_LOCALE = 'nb-NO'
const contentfulPrimerFile = () => import('@/contentful/initial-sync.json')

export const getMessages = async () => {
  const primerData = await contentfulPrimerFile()
  const mapped = updateMessages(primerData)

  // Perform some sort of sync to get deltas here

  return mapped
}

let i18n: I18n | null = null

export const initI18n = async (userLanguage: string = ''): Promise<I18n> => {
  if (!i18n) {
    const messages = await getMessages()
    i18n = createI18n({
      legacy: false,
      globalInjection: true,
      locale: userLanguage || DEFAULT_CONTENTFUL_LOCALE,
      fallbackLocale: {
        default: [DEFAULT_CONTENTFUL_LOCALE]
      },
      messages
    })
  }
  return i18n
}

export const useI18n = () => i18n

export const i18nPlugin = {
  async install(app: any, i18n: I18n) {
    const i18nInstance = i18n
    app.use(i18nInstance)
  }
}

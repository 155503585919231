import { defineStore } from 'pinia'
import { ref } from 'vue'

export interface FeatureFlagStore {
  featureFlags: string[]
  isFeatureEnabled: () => boolean
  isAnyFeatureEnabled: () => boolean
}

export const useFeatureFlags = defineStore('featureFlags', () => {
  // State
  const featureFlags = ref<string[]>([])

  // Getters
  const isFeatureEnabled = (feature: string) =>
    featureFlags.value.includes(feature)

  const isAnyFeatureEnabled = (features: string[]) =>
    features.some(feature => featureFlags.value.includes(feature))

  const update = (features: string[]) => {
    featureFlags.value = features
  }

  return {
    featureFlags,
    // Getters
    isFeatureEnabled,
    isAnyFeatureEnabled,

    // Actions
    update
  }
})

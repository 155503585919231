import { useMonolithService } from '@/services/monolithService'

// Enum for monolith service operations
export enum MonolithOperationType {
  LOGIN = 'login',
  LOGOUT = 'logout',
  KEEP_ALIVE = 'keepAlive'
}

export const KEEP_ALIVE_INTERVAL_MS = 600000 // 10 minute
let keepAliveInterval: NodeJS.Timeout | null = null
let lastKeepAliveCall: number | null = null

// TODO: Use Luxon for date formatting
const formatDuration = (milliseconds: number): string => {
  const seconds = Math.floor((milliseconds / 1000) % 60)
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60)
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24)

  return `${hours > 0 ? `${hours}h ` : ''}${minutes > 0 ? `${minutes}m ` : ''}${seconds}s`
}

export const setLastKeepAliveCall = (dateInMs: number | null) => {
  lastKeepAliveCall = dateInMs
}

export const getKeepAliveInterval = (): NodeJS.Timeout | null =>
  keepAliveInterval
export const getLastKeepAliveCall = (): number | null => lastKeepAliveCall

export const shouldSkipKeepAlive = (): boolean => {
  console.info(
    'Amber:MonolithOperation:shouldSkipKeepAlive',
    `Last keep-alive call: ${lastKeepAliveCall}`
  )
  if (lastKeepAliveCall === null) return false // First call should never be skipped

  const timeSinceLastCall = Date.now() - lastKeepAliveCall
  const shouldSkip = timeSinceLastCall < KEEP_ALIVE_INTERVAL_MS

  if (shouldSkip) {
    const lastCallDate = new Date(lastKeepAliveCall).toLocaleString()
    const readableLastCallTime = formatDuration(timeSinceLastCall)
    const readableInterval = formatDuration(KEEP_ALIVE_INTERVAL_MS)

    console.info(
      'Amber:MonolithOperation:shouldSkipKeepAlive',
      `Skipping keep-alive: Last call was ${readableLastCallTime} ago (at ${lastCallDate}), within the ${readableInterval} interval.`
    )
  }

  return shouldSkip
}

export const clearKeepAliveInterval = () => {
  if (keepAliveInterval) {
    clearInterval(keepAliveInterval)
    keepAliveInterval = null
  }

  setLastKeepAliveCall(null)
}

export const registerKeepMonolithAliveInterval = async () => {
  clearKeepAliveInterval()

  await handleMonolithAuth(MonolithOperationType.KEEP_ALIVE)
  setLastKeepAliveCall(Date.now())

  keepAliveInterval = setInterval(async () => {
    if (shouldSkipKeepAlive()) return

    try {
      await handleMonolithAuth(MonolithOperationType.KEEP_ALIVE)
      setLastKeepAliveCall(Date.now())
    } catch (error) {
      console.error('Error during monolith keep-alive:', error)
    }
  }, KEEP_ALIVE_INTERVAL_MS)
}

// Handles monolith authentication operations
export const handleMonolithAuth = async (
  action: MonolithOperationType,
  idToken?: string
) => {
  console.info('Amber:MonolithOperation:handleMonolithAuth', action)

  const monolithService = useMonolithService()

  switch (action) {
    case MonolithOperationType.LOGIN:
      if (!idToken) {
        console.error('No idToken provided for Monolith operation:', action)
        return
      }
      await monolithService.login(idToken)
      await registerKeepMonolithAliveInterval()
      break

    case MonolithOperationType.LOGOUT:
      await monolithService.logout()
      clearKeepAliveInterval()
      break

    case MonolithOperationType.KEEP_ALIVE:
      if (shouldSkipKeepAlive()) return
      await monolithService.keepAlive()
      break
  }
}
